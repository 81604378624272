import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  Button,
  Link,
} from "@mui/material";

const Documentation = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container
        className={loading ? "hide_map" : "show_map"}
        maxWidth={"xl"}
        sx={{
          height: { sm: "100%", md: "55vh" },
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            direction={"column"}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "flex-end",
                position: "absolute",
                height: "35px",
                backgroundColor: "#fff",
                width: "100%",
                left: "-103%",
                top: "10px",
                borderRadius: 15,
                p: 1,
              }}
            >
              <Box className="circle_red"></Box>
            </Box>
            <Box>
              <Typography
                variant="h1"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 2 }}
              >
                Documentation
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h6"
                sx={{ fontFamily: "VAG Rounded", color: "#FFF", my: 2 }}
              >
                Download the relevant documents below by clicking on the
                document name.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              {/* display the links to /privacy-policy /rica-act and /termsandconditions */}
              <List>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    my: 2,
                  }}
                >
                  <Button variant="contained" color="error">
                    <Link
                      underline="none"
                      sx={{
                        color: "#fff",
                        fontFamily: "VAG Rounded",
                        fontSize: "1.2rem",
                      }}
                      href="assets/megsApp-Privacy-Policy-24-June-2021-002.docx"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                  </Button>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    my: 2,
                  }}
                >
                  <Button variant="contained" color="error">
                    <Link
                      underline="none"
                      sx={{
                        color: "#fff",
                        fontFamily: "VAG Rounded",
                        fontSize: "1.2rem",
                      }}
                      href="assets/RICA-Act.pdf"
                      download={true}
                    >
                      RICA Act
                    </Link>
                  </Button>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    my: 2,
                  }}
                >
                  <Button variant="contained" color="error">
                    <Link
                      underline="none"
                      sx={{
                        color: "#fff",
                        fontFamily: "VAG Rounded",
                        fontSize: "1.2rem",
                      }}
                      href="/assets/megsApp-Subscriber-Terms-and-Conditions-24-May-2021.docx"
                    >
                      Terms and Conditions
                    </Link>
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Documentation;
