import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const CompetitionTerms = () => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container className={loading ? "hide_map" : "show_map"} maxWidth="md">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            direction={"column"}
            sx={{
              display: { xs: "flex", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "flex-end",
                position: "absolute",
                height: "35px",
                backgroundColor: "#fff",
                width: "100%",
                left: "-103%",
                top: "10px",
                borderRadius: 15,
                p: 1,
              }}
            >
              <Box className="circle_red"></Box>
            </Box>
            <Container
              sx={{
                m: 0,
                p: 0,
              }}
            >
              <Typography
                // variant="h1"
                sx={{
                  fontFamily: "VAG Rounded",
                  fontWeight: 400,
                  fontSize: "2.5rem",
                  color: "#fff",
                }}
              >
                Competition T&Cs
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                By entering this Competition, participants agree to be bound by
                these terms and conditions in their entirety.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Introduction{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                This Competition is being run by MEGSAPP RSA (Pty) Ltd
                (Registration Number 2017/252078/07), with its business office
                situated at 49 Via Salara, Irene Corporate Corner, Irene,
                Centurion, 0157 (“megsApp”).
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                These Competitions Terms & Conditions is an overview for all
                competitions for specific Terms & Conditions please refer to the
                “Competitions” section for specific competition Terms &
                Conditions.{" "}
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Eligibility to Enter{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                An eligible participant must be a natural person, at least 18
                years old and a resident in South Africa in order to enter the
                Competition.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                Employees of megsApp and its associates and their immediate
                family members may not participate in the Competition.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                By entering the Competition, the participant confirms that
                he/she is eligible to do so and that he/she is eligible to
                receive any prizes that may be awarded to the participant
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                Winners must be on megsApp or be willing to switch to megsApp in
                order to claim the prize.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Data Protection and Publicity{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp respects the privacy of the participants and will handle
                all participants’ personal information in accordance with
                applicable data protection laws and megsApp’s Privacy Policy.
                Personal information provided by participants will only be used
                for the purpose of administering the Competition unless
                otherwise stated.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                Entrants may request information on the winner by emailing
                support@megsapp.com
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                By accepting the prize, the winner agrees to participate in any
                promotional activities associated with the Competition,
                including but not limited to the use of his/her name, likeness,
                and submitted content for marketing and publicity purposes.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winner’s first name can be announced on megsApp ‘s website
                and social media channel if megsApp decides to.
              </Typography>

              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Limitation of Liability{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp accepts no responsibility for any lost, late,
                misdirected, or incomplete entries, or for any technical
                malfunction, computer error, or other events beyond their
                control that may cause the Competition to be disrupted or
                corrupted.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                This Competition is in no way sponsored, endorsed, or
                administered by, or associated with, any social media platform
                used for promotion.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                General{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp reserves the right, at any time and without prior
                notice, to cancel the competition or amend these terms and
                conditions.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                These terms and conditions shall be governed by and construed in
                accordance with the laws of South Africa. Any disputes arising
                out of or in connection with this Competition shall be subject
                to the exclusive jurisdiction of the courts of South Africa
              </Typography>

              <Typography
                variant="h3"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Competitions:{" "}
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3, mt: 6 }}
              >
                "50GB Non-Expiring data"
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                This competition will run from 12PM on 28th June 2023 and shall
                end at 12PM on 12th July 2023 (“the Competition Period”)
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                To Enter the Competition{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                In order to enter the Competition, participants must complete
                the following steps:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                Purchase 1GB of non-expiry data (R49) via USSD or the megsApp{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                For every 1GB of non-expiry data (R49) purchased is equal to 1
                entry (purchase as many times as you like)
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                By entering the Competition, participants agree to be bound by
                these terms and conditions.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                The Prize{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winning prize will be{" "}
                <strong>“50GB of Non-Expiring data”</strong> – being 50GB of
                Non-Expiring data delivered free of charge, once off, to the
                winner’s megsApp SIM Card.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The prize is linked exclusively to the winner in their personal
                capacity, is non-transferable, and cannot be exchanged for cash
                or any other product or service.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp reserves the right to substitute prizes of equal or
                greater value if circumstances beyond our control require doing
                so.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp decision on any aspect of the Competition is final and
                binding, and no correspondence will be entered into in respect
                thereof.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Winner Announcement{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winner will be selected randomly from all eligible entries
                received during the Competition Period. The draw will take place
                on the 13th July 2023.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winner will be notified via direct messaging on WhatsApp or
                call. megsApp will make two attempts to contact the winner via
                direct messaging on WhatsApp and two attempts to contact the
                winner via call
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                If the winner does not respond within fourteen days of the
                notification, he/she will forfeit their right to the prize, and
                megsApp shall select and notify another winner.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp may require proof of identity, age, and residency from
                the winner before awarding the prize. Failure to provide such
                proof may result in disqualification.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp reserves the right to disqualify any participant who
                breaches these terms and conditions or engages in any
                fraudulent, offensive, or inappropriate conduct.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Delivery of the Prize{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp will assist the winner in accessing the “50GB of
                non-expiring data” via megsApp, which the winner will be
                required to download and register to use (which registration is
                free of charge), in order to access the prize.
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3, mt: 6 }}
              >
                "WIN your share of 50GB of non-expiring data"
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                This competition will run from 12AM on 24th July 2023 and shall
                end at 12AM on 9th August 2023 (“the Competition Period”)
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                To Enter the Competition{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                In order to enter the Competition, participants must complete
                the following steps:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                Purchase ANY 1GB (includes non-expiry, 1 Day only or 30 day)
                data via USSD or the megsApp{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                For every 1GB data purchased is equal to 1 entry (purchase as
                many times as you like)
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                OR{" "}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                Purchase 220MB of non-expiry data (R19) via USSD or the megsApp{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                For every 220MB of non-expiry data (R19) purchased is equal to 1
                entry (purchase as many times as you like)
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                By entering the Competition, participants agree to be bound by
                these terms and conditions.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                The Prize{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winning prize will be “WIN your share of 50GB of
                non-expiring data” – being 2GB of Non-Expiring data to 25
                winners delivered free of charge, once off, to the winner’s
                megsApp SIM Card.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The prize is linked exclusively to the winner in their personal
                capacity, is non-transferable, and cannot be exchanged for cash
                or any other product or service.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp reserves the right to substitute prizes of equal or
                greater value if circumstances beyond our control require doing
                so.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp decision on any aspect of the Competition is final and
                binding, and no correspondence will be entered into in respect
                thereof.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Winner Announcement{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winner will be selected randomly from all eligible entries
                received during the Competition Period. The draw will take place
                on the 8th August 2023.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winner will be notified via direct messaging on WhatsApp or
                In-App (megsApp App)
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp may require proof of identity, age, and residency from
                the winner before awarding the prize. Failure to provide such
                proof may result in disqualification.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp reserves the right to disqualify any participant who
                breaches these terms and conditions or engages in any
                fraudulent, offensive, or inappropriate conduct.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Delivery of the Prize{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp will assist the winner in accessing the “WIN your share
                of 50GB of non-expiring data” via megsApp, which the winner will
                be required to download and register to use (which registration
                is free of charge), in order to access the prize.
              </Typography>

              <Typography
                variant="h4"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3, mt: 6 }}
              >
                "1-Day Madness"
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                This competition will run from 12AM on 24th August 2023 and
                shall end at 12AM on 31st August 2023 (“the Competition Period”)
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                To Enter the Competition{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                In order to enter the Competition, participants must complete
                the following steps:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                Purchase ANY 1 Day bundle (includes 1GB-1 Day, 2GB-1 Day, 3GB -
                1 Day) data via USSD or the megsApp{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                For every bundle data purchased it is equal to 1 entry (purchase
                as many times as you like)
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                By entering the Competition, participants agree to be bound by
                these terms and conditions.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                The Prize{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winning prize will be “1 Day MADNESS” – being 5GBof
                Non-Expiring data to 10 winners delivered free of charge, once
                off, to the winner’s megsApp SIM Card.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The prize is linked exclusively to the winner in their personal
                capacity, is non-transferable, and cannot be exchanged for cash
                or any other product or service.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp reserves the right to substitute prizes of equal or
                greater value if circumstances beyond our control require doing
                so.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp decision on any aspect of the Competition is final and
                binding, and no correspondence will be entered into in respect
                thereof.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Winner Announcement{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winner will be selected randomly from all eligible entries
                received during the Competition Period. The draw will take place
                on the 1stSeptember 2023.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                The winner will be notified via direct messaging on WhatsApp or
                In-App (megsApp App)
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp may require proof of identity, age, and residency from
                the winner before awarding the prize. Failure to provide such
                proof may result in disqualification.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp reserves the right to disqualify any participant who
                breaches these terms and conditions or engages in any
                fraudulent, offensive, or inappropriate conduct.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
              >
                Delivery of the Prize{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  textAlign: "justify",
                  my: 3,
                }}
              >
                megsApp will assist the winner in accessing the “1 Day MADNESS”
                via megsApp, which the winner will be required to download and
                register to use (which registration is free of charge), in order
                to access the prize.
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CompetitionTerms;
