import React from "react";
import { Container, Grid, Box, Typography, Button } from "@mui/material";
const Footer = () => {
  return (
    <footer>
      <Container sx={{ pt: 6, pb: 6 }} maxWidth="xl">
        <Box
          sx={{
            height: "10px",
            width: "100%",
            backgroundColor: "#e41f26",
            borderRadius: "15px",
          }}
        ></Box>
        <Grid container sx={{ mt: 4 }} spacing={2}>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: { xs: 2, md: 0 },
            }}
          >
            <img
              className="app_logo"
              alt="MegsApp Logo"
              src={"./assets/megsapp-logo-white.png"}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                noWrap
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Sitemap
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="/"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Home
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="/about"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  About Us
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="/get-sim"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Get a SIM
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="/self-rica"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Self RICA
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="/port-number"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Port my number
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="https://support.megsapp.com/"
                  target={"_blank"}
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  rel="noreferrer"
                >
                  Support
                </a>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                noWrap
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Social Links
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                WhatsApp: 063 901 0000
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                Informational USSD: *135#
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                Top-up & Balance: USSD *136#
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                Contact us:{" "}
                <a
                  href="mailto:support@megsapp.com"
                  style={{
                    my: 2,
                    color: "#fff",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  support@megsapp.com
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                noWrap
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Documentation
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  // href="/assets/megsApp-Subscriber-Terms-and-Conditions-24-May-2021.docx"
                  href="/terms"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Terms and Conditions
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  // href="assets/megsApp-Privacy-Policy-24-June-2021-002.docx"
                  href="/privacy-policy"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="assets/RICA-Act.pdf"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  RICA Act
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="assets/eSIM Terms and Conditions.pdf"
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  eSIM Terms and Conditions
                </a>
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <a
                  href="https://megsapp.com/assets/megsApp_self_onboarding_infographic_v2.pdf"
                  target={"_blank"}
                  style={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  rel="noreferrer"
                >
                  Self RICA Guide
                </a>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                noWrap
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Get The App
              </Typography>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    target={"_blank"}
                    href="https://apps.apple.com/za/app/megsapp/id1568554026"
                  >
                    <img
                      className="apple_play_img"
                      alt="apple_play_img"
                      src={"./assets/apple-app-store-logo.webp"}
                    />
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    target={"_blank"}
                    href="https://play.google.com/store/apps/details?id=za.co.megsapp"
                  >
                    <img
                      className="google_play_img"
                      alt="google_play_img"
                      src={"./assets/google-play-store-logo.webp"}
                    />
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    target={"_blank"}
                    href="https://appgallery.huawei.com/app/C107620767"
                  >
                    <img
                      className="google_play_img"
                      alt="huawei_img"
                      src={"./assets/huawei-app-gallery-logo.webp"}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer