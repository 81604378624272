import { Box, Container, Divider, Grid, Link, List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const TermsAndConditions = () => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container className={loading ? "hide_map" : "show_map"} maxWidth={"md"}>
        <Grid
          item
          xs={12}
          md={6}
          direction={"column"}
          sx={{
            display: { xs: "flex", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "absolute",
              height: "35px",
              backgroundColor: "#fff",
              width: "100%",
              left: "-103%",
              top: "10px",
              borderRadius: 15,
              p: 1,
            }}>
            <Box className="circle_red"></Box>
          </Box>

          <Container
            sx={{
              m: 0,
              p: 0,
            }}>
            <Typography
              // variant="h1"
              sx={{
                fontFamily: "VAG Rounded",
                fontWeight: 400,
                fontSize: "2.5rem",
                color: "#fff",
              }}>
              Terms and Conditions of use for megsApp Services
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Introduction
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  1.1 These Terms and Conditions are applicable to you when you make use of megsApp’s Services which are activated when your prepaid SIM Card is
                  connected to the megsApp Network through the Apparatus.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  1.2 By purchasing any of megsApp’s Services you automatically bind yourselves to these Terms and Conditions and acknowledge that these Terms
                  and Conditions:
                </Typography>
              </ListItem>

              <List
                sx={{
                  color: "#fff",
                  marginLeft: 3,
                }}>
                <ListItem>
                  <Typography variant="body1">1.2.1 may limit the risk or liability of megsApp or a third party; and/or</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">1.2.2 may create risk or liability for you; and/or</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">1.2.3 may compel you to indemnify megsApp or a third party; and/or</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">1.2.4 serve as an acknowledgment, by you, of a fact.</Typography>
                </ListItem>
              </List>
            </List>

            {/* Definitions */}
            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Definitions
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  2.1 <strong>“Apparatus”</strong> means any mobile, transportable or portable cellular mobile terminal, handset, laptop, computer, modem or
                  other apparatus which is approved by the Regulatory Authority and which is capable of connection by radio interface to the Network.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.2 <strong>“megsApp”</strong> means megsApp RSA (Pty) Ltd (Registration Number 2017/252078/07), a private company with limited liability duly
                  registered and incorporated in accordance with the company laws of the Republic of South Africa;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.3 <strong>“megsApp Group”</strong> means megsApp and any of its subsidiary companies;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.4 <strong>“megsApp Representative”</strong> means a person who is duly authorized to represent megsApp with the role and function of selling
                  and activating megsApp SIM cards;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.5 <strong>“megsApp Services”</strong> means the cellular telecommunications service provided by means of the Network as well as such other
                  additional related services, equipment, Value Added Services and/or Financial Services;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.6 <strong>“Network”</strong> means the public mobile telecommunications system operated and made available by megsApp or any other members
                  of the megsApp Group;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.7 <strong>“Regulatory Authority”</strong> means the Independent Communication Authority of South Africa (ICASA) or any similar authority
                  that is established in the future to perform or take over the functions of ICASA;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.8 <strong>“SIM Card”</strong> means the Subscriber Identity Module Card allocated to you to enable you to gain access to the Network by
                  using the SIM Card in the Apparatus;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.9 <strong>“Starter Pack”</strong> means the pack that includes the user guide and SIM Card;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.10 <strong>“Subscriber Identity Module Card”</strong> means the SIM Card allocated to you which identifies your subscription to megsApp;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.11 <strong>“Terms and Conditions”</strong> means these terms and conditions and any other terms and conditions which may be amended in the
                  future and uploaded on the megsApp website{" "}
                  <Link
                    sx={{
                      "px": 1,
                      "color": "#fff",
                      "textDecoration": "underline",
                      "&:hover": {
                        color: "#FF0000",
                        textDecoration: "underline",
                      },
                    }}
                    href="http://www.megsapp.com/termsandconditions">
                    (www.megsapp.com/termsandconditions)
                  </Link>
                  ; and
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2.12 <strong>“Value Added Services”</strong> means and services provided by megsApp which are non-core services and are services beyond
                  standard voice calls, the content of which may not have anything to do with megsApp’s mobile network and/or services.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Provision of the megsApp Services
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  3.1 In order to receive your new cellphone number and to activate your SIM Card found in your Starter Pack, you must register your SIM Card,
                  in terms of the provisions of the Regulation of Interception of Communications and Provision of Communications Related Information Act, 2002
                  (“RICA”). The megsApp Representative selling the Starter Pack will assist you in ensuring the SIM Card has been correctly RICA’d and register
                  you on the Network immediately subsequent to your purchase of the Starter Pack.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  3.2 Once your SIM Card has been RICA’d, insert your SIM Card into your Apparatus and you will be connected to the megsApp Network and megsApp
                  Services and receive your cellphone number.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  3.3 Subject to the Terms and Conditions, megsApp will activate the SIM Card and maintain the availability of the megsApp Services to you
                  whilst your SIM Card is active on the Network.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  3.4 megsApp provides free access to the megsApp Services and platforms on the megsApp mobile application “(megsApp App)” which is free of
                  charge for all registered megsApp users.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  3.5 megsApp operates using a fair usage policy which grants each megsApp user 100MB (one hundred megabytes) of free data access per day to be
                  used on the megsApp App. Should the megsApp user use more than the daily allotted 100MB (one hundred megabytes) on the megsApp App, megsApp
                  reserves the right to charge the megsApp user for the additional data used.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  3.6 megsApp reserves the right to adjust, amend or discontinue the megsApp Services, by giving advance notice where reasonably possible and
                  dependent on the circumstances, based on the megsApp user’s use of the megsApp Services.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Cellphone Number Portability
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  4.1. Should you wish to port-in your cellphone number from your previous network provider you will be required to indicate this when the
                  megsApp Representative is registering you on the Network. Should you qualify to port your cellphone number, an additional administration fee
                  will be charged.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">4.2. You may not port if:</Typography>
              </ListItem>
              <List sx={{ marginLeft: 3 }}>
                <ListItem>
                  <Typography variant="body1">4.2.1. any network operator has suspended your service;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4.2.2. your cellphone number has already been disconnected;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4.2.3. you have ported your cellphone number in the previous 60 (sixty) days;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4.2.4. your cellphone number is already waiting to be ported;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4.2.5. you have provided the incorrect details; or</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4.2.6. you do not have permission to port for whatever reason.</Typography>
                </ListItem>
              </List>
              <ListItem>
                <Typography variant="body1">
                  4.3. Porting is not capable of taking place on Sundays or public holidays and can only be activated in the late evening on the port date you
                  request, or the following day.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  4.4. A port may be reversed on the instruction of the megsApp user but will only be permitted in the following circumstances:
                </Typography>
              </ListItem>
              <List sx={{ marginLeft: 3 }}>
                <ListItem>
                  <Typography variant="body1">
                    4.4.1. Within 60 (sixty) days after the date that the number was ported from the megsApp user’s previous service provider; and
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">4.4.2. the megsApp user and their previous service provider agree to the reversal of the port.</Typography>
                </ListItem>
              </List>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Changing of Cellphone Number
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  5.1. It is possible for the megsApp user to change their megsApp cellphone number without the need to purchase a new SIM card.The process to
                  be followed in order to change your megsApp cellphone number is available on the megsApp App.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Value Added Services
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  6.1. You acknowledge that should you subscribe to any of the Value Added Services, the provision of such Value Added Services will be subject
                  to the terms and conditions applicable thereto which you undertake to make yourself familiar with.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Disclosure of Information
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  7.1. You consent that megsApp may, to the extent permitted by law, receive or disclose your personal information, documents, detailed call
                  records, credit profile information and/or any other credit information from or to:
                </Typography>
              </ListItem>
              <List sx={{ marginLeft: 3 }}>
                <ListItem>
                  <Typography variant="body1">7.1.1. any credit providers, credit bureau or credit reporting agencies;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    7.1.2. any law enforcement agencies that require the information for the prevention or investigation of criminal activities;
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    7.1.3. any of megsApp’s shareholders, related entities, suppliers, agents, professional advisors or any company with the megsApp Group for
                    marketing purposes, subject to your right to restrict receipt of unwanted marketing material or other rights in terms of the Consumer
                    Protection Act, 2008 and the Protection of Personal Information Act 4 of 2013;
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    7.1.4. any of megsApp’s shareholders, related entities, suppliers, agents or professional advisors for reporting, accounting, product supply
                    and service and/or auditing purposes; or
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    7.1.5. any company within the megsApp Group for any purpose connected with the megsApp Services or the Network or for incentive scheme
                    purposes.
                  </Typography>
                </ListItem>
              </List>
              <ListItem>
                <Typography variant="body1">
                  7.2. Despite any part of the Terms and Conditions that may indicate otherwise, megsApp shall be entitled to utilize your detailed call records
                  for any lawful purpose including but not limited to tracing and/or collection purposes.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Acknowledgment
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <Typography variant="body1" textAlign="left">
                You acknowledge and agree that –
              </Typography>
              <List sx={{ marginLeft: 3 }}>
                <ListItem>
                  <Typography variant="body1">
                    8.1. the service quality and coverage available to you shall be limited to that provided by the Network in accordance with the requirements
                    of the Electronic and Communications Act, 2005 (as amended) (“ECTA”) and the Regulatory Authority requirements. The megsApp Services may,
                    from time to time, be adversely affected by such physical features such as buildings and underpass, as well as atmospheric conditions and
                    other causes of interference; and
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    8.2. to the extent permitted by law, megsApp shall not be liable for non-availability of the megsApp Services.
                  </Typography>
                </ListItem>
              </List>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Liability
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  9.1. You indemnify and hold megsApp and each company within the megsApp Group harmless against any expense, loss, claim, harm or damage
                  brought against, suffered or sustained by you or any third party, which arises directly or indirectly out of a breach of these Terms and
                  Conditions by you or by your use of the megsApp Services and/or the Apparatus, other than in respect of losses caused by the gross negligence
                  or intentional misconduct of megsApp or any company within the megsApp Group or any of their respective employees, directors or agents.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  9.2. Without affecting the generality of clause 8.1 above, megsApp shall not be liable to you for any breach of these Terms and Conditions or
                  failure on megsApp’s part to perform any obligations as a result of technical problems relating to the Network, termination of any license to
                  operate or use the Network, act of God, government control, restrictions or prohibitions or other government act or omission, whether local or
                  national, act of default of any supplier, agent or sub-contractor, industrial disputes or any other cause beyond the control of megsApp.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Undertakings
            </Typography>
            <Typography variant="body1" sx={{ color: "#fff", textAlign: "left" }}>
              You shall –
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  10.1. comply with all instructions issued by megsApp which concern your use of the megsApp Services, the Apparatus or related matters;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  10.2. not be entitled to commercially exploit the megsApp Services in any manner whatsoever without megsApp’s prior written consent, including
                  without limitation, the provision of telecommunication services to third parties;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  10.3. provide megsApp with all such necessary information as megsApp may, in its sole discretion, reasonable and lawfully require;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  10.4. only make use of an Apparatus which is approved for use in conjunction with the Network by megsApp; and
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  10.5. engage with megsApp, other members of the megsApp Group and their respective staff and agents in a courteous, cordial and respectful
                  manner at all times.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Suspension/Disconnection
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  11.1. megsApp may from time to time, by giving you advance notice where reasonably possible and dependent on the circumstances, suspend the
                  megsApp Services or disconnect your cellphone number from the Network for a period to be determined by megsApp, in any one of the following
                  circumstances:
                </Typography>
              </ListItem>
              <List sx={{ marginLeft: 3 }}>
                <ListItem>
                  <Typography variant="body1">11.1.1. if the megsApp Services have not been used by you for a period in excess of 60 (sixty) days;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">11.1.2. during any technical failure, modification or maintenance of the Network;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">11.1.3. if you fail to comply with any of the Terms and Conditions;</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">11.1.4. if so directed by the Regulatory Authority; or</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">11.1.5. in any other instance specifically provided for in the Terms and Conditions.</Typography>
                </ListItem>
              </List>
              <ListItem>
                <Typography variant="body1">
                  11.2. Having regard to the circumstances at the time of the suspension, disconnection or reconnection, as the case may be, megsApp shall be
                  entitled to levy a reasonable fee in respect of such suspension, disconnection and/or any reconnection.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  11.3. At the time of disconnection should the megsApp user have any data or minutes in credit, same will not be able to be paid in any way to
                  the megsApp user.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Communication
            </Typography>

            <Typography variant="body1" sx={{ color: "#fff", textAlign: "justify" }}>
              You hereby consent to the dispatch or transmission by megsApp of all and necessary communication in terms of ECTA or subsequently enacted and
              relevant legislation. You are entitled to inform megsApp if you do not wish to receive any communications for purposes of direct marketing and in
              this instance, megsApp will refrain from circulating any further such material to you.
            </Typography>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Disputes
            </Typography>

            <Typography variant="body1" sx={{ color: "#fff", textAlign: "justify" }}>
              Any dispute relating to the Network may be referred to the ICASA or to the National Consumer Commission established under the Consumer Protection
              Act, 2008.
            </Typography>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Lost or Stolen SIM Cards and/or Apparatus
            </Typography>

            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">14.1 You are responsible for the safekeeping of your SIM Card and/or Apparatus.</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  14.2 You must notify megsApp immediately if you realize that your SIM Card and/or Apparatus has been lost or stolen.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  14.3 You must also, within 24 (twenty-four) hours of you realizing that your SIM Card and/or Apparatus has been lost or stolen, report this to
                  the South African Police Service.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  14.4 In the event that your SIM card is lost or stolen, it is possible to replace the SIM card and keep the existing number by exercising one
                  of the following options:
                </Typography>
              </ListItem>
              <List sx={{ marginLeft: 3 }}>
                <ListItem>
                  <Typography variant="body1">
                    14.4.1 purchase a Starter Pack from a megsApp Representative and inform the megsApp Representative that you wish to retain your existing
                    number before the megsApp Representative commences the RICA process; or
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    14.4.2 purchase a replacement SIM card in the megsApp App which will provide the relevant details and procedures to be followed.
                  </Typography>
                </ListItem>
              </List>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Password and/or One Time Pin
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  If you have a password or One Time PIN (“OTP”) sent to your SIM Card or stored on your Apparatus, you undertake to keep it secure and
                  undertake that no other person shall use the megsApp Services by means of utilizing your password or OTP, and you further acknowledge that you
                  are responsible for ensuring that no unauthorized access to the password or OTP is obtained, and that you will be liable for all such
                  activities conducted pursuant to such use, whether authorized or not.
                </Typography>
              </ListItem>
            </List>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              General
            </Typography>
            <List sx={{ color: "#fff", listStyle: "disc" }}>
              <ListItem>
                <Typography variant="body1">
                  16.1. These Terms and Conditions shall be governed by and construed in accordance with the laws of South Africa, and you shall submit to the
                  jurisdiction of the South African courts.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  16.2. These Terms and Conditions are severable, in that if any provision is determined to be illegal or unenforceable by any court holding the
                  necessary jurisdiction, then such provision shall be deemed to have been deleted without affecting the remaining provision of the Terms and
                  Conditions.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  16.3. megsApp’s failure to exercise any particular rights or provision of these Terms and Conditions shall not constitute a waiver of such
                  right or provision, unless acknowledged and agreed to by megsApp in writing.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  16.4. Any amended terms and conditions published in the media, or on the megsApp website
                  <Link
                    sx={{
                      "px": 1,
                      "color": "#fff",
                      "textDecoration": "underline",
                      "&:hover": {
                        color: "#FF0000",
                        textDecoration: "underline",
                      },
                    }}
                    href="http://www.megsapp.com/termsandconditions">
                    (www.megsapp.com/termsandconditions)
                  </Link>
                  will form part of the terms and conditions of the megsApp Services, to which terms and conditions you agree to be bound.
                </Typography>
              </ListItem>
            </List>
            <Divider sx={{ backgroundColor: "#fff", my: 3 }} />
          </Container>
          <Container
            sx={{
              m: 0,
              p: 0,
            }}>
            <Typography
              sx={{
                fontFamily: "VAG Rounded",
                fontWeight: 400,
                fontSize: "2.5rem",
                color: "#fff",
                // mt: 3,
              }}>
              Competitions
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 700,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              By entering this Competition, participants agree to be bound by these terms and conditions in their entirety.
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Introduction{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              This Competition is being run by MEGSAPP RSA (Pty) Ltd (Registration Number 2017/252078/07), with its business office situated at 49 Via Salara,
              Irene Corporate Corner, Irene, Centurion, 0157 (“megsApp”).
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              These Competitions Terms & Conditions is an overview for all competitions for specific Terms & Conditions please refer to the “Competitions”
              section for specific competition Terms & Conditions.{" "}
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Eligibility to Enter{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              An eligible participant must be a natural person, at least 18 years old and a resident in South Africa in order to enter the Competition.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              Employees of megsApp and its associates and their immediate family members may not participate in the Competition.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              By entering the Competition, the participant confirms that he/she is eligible to do so and that he/she is eligible to receive any prizes that may
              be awarded to the participant
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              Winners must be on megsApp or be willing to switch to megsApp in order to claim the prize.
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Data Protection and Publicity{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              megsApp respects the privacy of the participants and will handle all participants’ personal information in accordance with applicable data
              protection laws and megsApp’s Privacy Policy. Personal information provided by participants will only be used for the purpose of administering the
              Competition unless otherwise stated.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              Entrants may request information on the winner by emailing support@megsapp.com
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              By accepting the prize, the winner agrees to participate in any promotional activities associated with the Competition, including but not limited
              to the use of his/her name, likeness, and submitted content for marketing and publicity purposes.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              The winner’s first name can be announced on megsApp ‘s website and social media channel if megsApp decides to.
            </Typography>

            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              Limitation of Liability{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              megsApp accepts no responsibility for any lost, late, misdirected, or incomplete entries, or for any technical malfunction, computer error, or
              other events beyond their control that may cause the Competition to be disrupted or corrupted.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              This Competition is in no way sponsored, endorsed, or administered by, or associated with, any social media platform used for promotion.
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}>
              General{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              megsApp reserves the right, at any time and without prior notice, to cancel the competition or amend these terms and conditions.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}>
              These terms and conditions shall be governed by and construed in accordance with the laws of South Africa. Any disputes arising out of or in
              connection with this Competition shall be subject to the exclusive jurisdiction of the courts of South Africa
            </Typography>

          </Container>

          <Box
            ref={inputRef}
            sx={{
              position: "absolute",
              bottom: 0,
              right: "-75%",
              zIndex: -1,
              width: "100%",
            }}>
            <img src={"./assets/megsapp-clear-logo.webp"} style={{ width: "40%" }} alt="megsapp-logo" />
          </Box>
        </Grid>
      </Container>
    </>
  );
};
export default TermsAndConditions;
