import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import GoogleMapReact from "google-map-react";
import { SearchBox } from "./components/SearchBox";

import { getTraderLocations } from "./actions";

import LocationOnIcon from "@mui/icons-material/LocationOn";

const FindTrader = () => {
  const [loading, setLoading] = useState(true);
  const [locationAvailable, setLocationAvailable] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapAPI] = useState(null);
  const [center, setCenter] = useState({
    lat: -26.127952135057914,
    lng: 27.978563130665883,
  });
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [defualtLocation, setDefualtLocation] = useState({
    lat: -26.127952135057914,
    lng: 27.978563130665883,
  });
  const [div_distance, setDivDistance] = useState(0);
  const [trader_locations, setTraderLocations] = useState([]);

  const inputRef = useRef();

  useEffect(() => {
    console.log("here");
    if ("geolocation" in navigator) {
      console.log('"geolocation" in navigator');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLocationAvailable(true);
        },
        () => {
          setLocationAvailable(false);
        }
      );
    } else {
      console.log(' outside "geolocation" in navigator');
    }
  }, []);

  useEffect(() => {
    (async () => {
      console.log("userLocation", userLocation);
      console.log("locationAvailable", locationAvailable);

      if (locationAvailable !== null) {
        console.log("mapInstance", mapInstance);
        if (mapInstance) {
          let api_location;

          if (locationAvailable) {
            api_location = userLocation;
            mapInstance.setCenter(userLocation);
            mapInstance.setZoom(16);
          }

          if (!locationAvailable) {
            api_location = defualtLocation;
            mapInstance.setCenter(defualtLocation);
            mapInstance.setZoom(16);
          }

          if (api_location) {
            await fetchTraderLocations(api_location);
            setLoading(false);
          }
        }
      } else {
        console.log("locationAvailable is null");
      }
    })();
  }, [locationAvailable, userLocation]);

  const fetchTraderLocations = async (location) => {
    let response = await getTraderLocations(location);
    setTraderLocations(response);
  };

  // useEffect(() => {
  //     const current = inputRef.current.getBoundingClientRect()
  //     setDivDistance(current.left);
  // }, [inputRef]);

  const apiHasLoaded = (map, maps) => {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapAPI(maps);
  };

  const handleOnPlacesChange = useCallback(
    (e) => {
      if (e && e[0] && e[0].geometry) {
        const lat = e[0].geometry.location.lat();
        const lng = e[0].geometry.location.lng();

        mapInstance.setCenter({ lat, lng });
        mapInstance.setZoom(16);

        fetchTraderLocations({ lat, lng });
      }
    },
    [mapInstance]
  );

  const MarkerComponent = ({ name, address }) => {
    return <Box className={"pin"}></Box>;
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "65vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Box
        className={loading ? "hide_map" : "show_map"}
        sx={{
          height: "65vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            language: "en",
            region: "ZA",
            libraries: ["places", "geometry", "drawing", "visualization"],
          }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {trader_locations &&
            trader_locations.length !== 0 &&
            trader_locations.map((pin) => (
              <MarkerComponent
                key={pin.lat}
                lat={pin.lat}
                lng={pin.lng}
                name={pin.name}
                address={pin.address}
              />
            ))}
        </GoogleMapReact>
      </Box>
      <Container maxWidth={"xl"}>
        <Box
          direction={"column"}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: { xs: "flex-start", md: "flex-start" },
            textAlign: { xs: "left", md: "left" },
            pt: 20,
          }}
        >
          {mapApiLoaded && (
            <SearchBox
              mapInstance={mapInstance}
              mapApi={mapApi}
              onPlacesChanged={handleOnPlacesChange}
              placeholder={"search"}
            />
          )}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "absolute",
              height: "35px",
              backgroundColor: "#fff",
              width: "100%",
              left: "-103%",
              top: "67%",
              borderRadius: 15,
              p: 1,
            }}
          >
            <Box className="circle_red"></Box>
          </Box>
          <Box>
            <Typography
              variant="h1"
              sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
            >
              megstrader Near You
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#fff" }}
            >
              Remember to bring your documents with for RICA purposes
              (ID/Passport & Proof of residence)
            </Typography>
          </Box>
        </Box>

        <Grid
          container
          direction={"row"}
          sx={{ display: "flex", mt: { sx: 0, md: 6 } }}
        >
          {trader_locations &&
            trader_locations.length !== 0 &&
            trader_locations.map((pin, i) => (
              <Grid
                key={i}
                item
                xs={12}
                md={3}
                direction={"column"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    border: 2,
                    borderColor: "#fff",
                    borderRadius: "10px",
                    textAlign: "left",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      ml: 3,
                      mt: 3,
                      fontFamily: "VAG Rounded",
                      color: "#fff",
                    }}
                  >
                    {pin.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      ml: 3,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      color: "#fff",
                    }}
                  >
                    {pin.address}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      target={"_blank"}
                      href={`https://www.google.com/maps/search/?api=1&query=${pin.lat},${pin.lng}`}
                      color="secondary"
                      size="large"
                      variant="contained"
                      sx={{
                        width: "90%",
                        mb: 3,
                        mt: 3,
                        pl: 5,
                        pr: 5,
                        textTransform: "initial",
                        borderRadius: "10px",
                        margin: "3 auto",
                      }}
                      endIcon={<LocationOnIcon />}
                    >
                      Go to Pin
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
        {/* 
            <Grid container spacing={2} sx={{ mt: {xs: 0 , md: 6}, position: 'relative', mb: 4 }}>

                <Grid item xs={12} md={6} direction={'column'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                    <Box ref={inputRef} sx={{ marginLeft: {xs: -div_distance} }}>
                        <img className='rates-image' src={'./assets/get-a-sim.webp'} style={{left: -div_distance }}/>
                   </Box>
                </Grid>

                <Grid item xs={12} md={6} direction={'column'} sx={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: {xs: 'flex-start', md: 'flex-end'}, textAlign: {xs: 'left', md: 'right'}}}>
                    <Box sx={{display: { xs: 'none', md: 'block'}, position: 'absolute', height: '35px', backgroundColor: '#fff', width: '100%', right: '-107%', top: 35, borderRadius: 15, p: 1}}>
                        <Box className='circle_red'></Box>
                    </Box>
                    <Box>
                        <Typography variant="h1" sx={{ mt: 2,  fontFamily: 'VAG Rounded', color: '#fff'}}>No Trader Near You?</Typography>
                        <Typography variant="body1"  sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#fff'}}>Enter your details below and we'll get you your megsApp SIM Card.</Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ mt:2}}>
                        <Grid item xs={12} md={12} direction={'column'}>
                            <TextField fullWidth color='input' id="outlined-basic" label="Full Name & Surname" variant="outlined" sx={{ input: { color: '#f7f7f7' }, "& .MuiInputLabel-root": {color: '#f7f7f7'}, '& .MuiOutlinedInput-root': { '& fieldset': { border: '2px solid #f7f7f7'}, '&:hover fieldset': { border: '2px solid #f7f7f7'},'&.Mui-focused fieldset': {border: '2px solid #f7f7f7'}}}} />
                        </Grid>
                        <Grid item xs={12} md={6} direction={'column'}>
                            <TextField fullWidth color='input' id="outlined-basic" label="Mobile Number" variant="outlined" sx={{ input: { color: '#f7f7f7' }, "& .MuiInputLabel-root": {color: '#f7f7f7'}, '& .MuiOutlinedInput-root': { '& fieldset': { border: '2px solid #f7f7f7'}, '&:hover fieldset': { border: '2px solid #f7f7f7' },'&.Mui-focused fieldset': {border: '2px solid #f7f7f7'} }}} />
                        </Grid>
                        <Grid item xs={12} md={6} direction={'column'}>
                            <TextField fullWidth color='input' id="outlined-basic" label="Email Address" variant="outlined" sx={{ input: { color: '#f7f7f7' }, "& .MuiInputLabel-root": {color: '#f7f7f7'}, '& .MuiOutlinedInput-root': { '& fieldset': { border: '2px solid #f7f7f7'}, '&:hover fieldset': { border: '2px solid #f7f7f7' },'&.Mui-focused fieldset': {border: '2px solid #f7f7f7'} }}} />
                        </Grid>
                        <Grid item xs={12} md={12} direction={'column'}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="mobile" sx={{color: '#f7f7f7' }} control={<Radio  sx={{color: '#f7f7f7','&.Mui-checked': {color: '#f7f7f7'}}} />} label="Contact me via Mobile Number" />
                                    <FormControlLabel value="email" sx={{color: '#f7f7f7' }} control={<Radio sx={{color: '#f7f7f7','&.Mui-checked': {color: '#f7f7f7'}}} />} label="Contact me via Email" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mt: 4}}>
                        <Button color='info' size='large' variant='contained' sx={{pl: 5, pr: 5, textTransform: 'initial', borderRadius: '10px'}} endIcon={<ArrowRightIcon />}>
                            Submit
                        </Button>
                    </Box>
                </Grid>

            </Grid> */}
      </Container>
    </>
  );
};
export default FindTrader;
