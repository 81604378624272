import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const SelfPorting = () => {
  // const inputRef = useRef();
  // const [div_distance, setDivDistance] = useState(0)
  const [loading, setLoading] = useState(true);
  const steps = [
    "Register your megsApp SIM (RICA)",
    'Send "Port" to 063 901 0001 on WhatsApp',
    "Enter the serial number of your megsApp SIM card",
    "Choose your current network provider",
    "Reply to the OTP SMS from your provider within 4 hours",
    "Log into the megsApp using your old phone number and password",
    "Track your port request on the megsApp or on WhatsApp",
    "Wait up to 24 hours before inserting your new megsApp SIM card",
  ];

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  // useEffect(() => {
  //     const current = inputRef.current.getBoundingClientRect()
  //     setDivDistance(current.right);
  // }, [inputRef]);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container className={loading ? "hide_map" : "show_map"} maxWidth={"xl"}>
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            direction={"column"}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: { xs: "center" },
              textAlign: { xs: "left", md: "left" },
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "flex-end",
                position: "absolute",
                height: "35px",
                backgroundColor: "#fff",
                width: "100%",
                left: "-103%",
                top: "10px",
                borderRadius: 15,
                p: 1,
              }}
            >
              <Box className="circle_red"></Box>
            </Box>
            <Box sx={{ mb: 2, textAlign: "center" }}>
              <Typography
                variant="h1"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                How to keep your existing number{" "}
                <Typography
                  component={"span"}
                  variant="h1"
                  sx={{
                    display: "inline-flex",
                    fontFamily: "VAG Rounded",
                    color: "#e41f26",
                  }}
                >
                  (Port):
                </Typography>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pt: 2,
                }}
              >
                After you've purchased your megsApp SIM card:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  color: "#e41f26",
                  pt: 2,
                  fontSize: "1.3rem",
                }}
              >
                Warning: Wait until your old SIM stops working before inserting
                your new megsApp SIM
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 2,
                p: { xs: 1, md: 2 },
                borderRadius: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <List
                sx={{
                  "& .MuiListItemIcon-root": {
                    mt: { sm: 0, md: -5 },
                    mb: 1,
                    p: { xs: 0.75, sm: 1, md: 2 },
                    minWidth: "2rem",
                    minHeight: "2rem",
                    backgroundColor: "#e41f26",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    color: "#fff",
                    fontFamily: "VAG Rounded",
                    fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                  },
                  "& .MuiListItem-root": {
                    display: "flex",
                    flexDirection: { sm: "row", md: "column" },
                  },
                  "& .MuiListItemText-root": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  },
                  "& .MuiTypography-root": {
                    p: { xs: 1, md: 2 },
                    mx: { xs: 2 },
                  },
                  "display": "flex",
                  "flexDirection": "row",
                  "flexWrap": "wrap",
                  "justifyContent": "center",
                }}
              >
                {steps.map((step, index) => (
                  <ListItem
                    sx={{
                      flexBasis: { md: "50%", lg: "30%" },
                      backgroundColor: "#FFF",
                      borderRadius: 4,
                      m: { xs: 2, md: 3 },
                      p: { sm: 2, md: 1 },
                    }}
                    key={index}
                  >
                    <ListItemIcon>{index + 1}</ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="body1"
                        sx={{
                          verticalAlign: "middle",
                          display: "inline-flex",
                          fontFamily: "Open Sans",
                          color: "#002846",
                          // if index is zero set font weight to bold
                          // fontWeight: index === 0 ? 700 : 400,
                          fontSize: index === 0 ? "1.2rem" : "1.1rem",
                          p: { sm: 2, md: 3 },
                        }}
                      >
                        {step}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{ mt: 2, fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Get your SIM & download our App{" "}
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }}
                  component="span"
                >
                  today?
                </Typography>
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }}
              >
                #achievemore
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                mt: 4,
              }}
            >
              <Button
                color="secondary"
                to="/get-sim"
                component={RouterLink}
                size="large"
                variant="contained"
                sx={{
                  pl: 5,
                  pr: 5,
                  textTransform: "initial",
                  borderRadius: "10px",
                }}
                endIcon={<ArrowRightIcon />}
              >
                PORT Now
              </Button>
            </Box>
          </Grid>
          {/* <Grid
              item
              xs={12}
              md={6}
              direction={"column"}
              sx={{
                display: { xs: "flex", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box ref={inputRef}>
                <img
                  src={"./assets/megsapp-clear-logo.webp"}
                  style={{ width: "140%" }}
                />
              </Box>
            </Grid> */}
        </Grid>
      </Container>
    </>
  );
};
export default SelfPorting;
