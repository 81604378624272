import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const Privacy = () => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container className={loading ? "hide_map" : "show_map"} maxWidth={"md"}>
        <Grid
          item
          xs={12}
          md={6}
          direction={"column"}
          sx={{
            display: { xs: "flex", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "absolute",
              height: "35px",
              backgroundColor: "#fff",
              width: "100%",
              left: "-103%",
              top: "10px",
              borderRadius: 15,
              p: 1,
            }}
          >
            <Box className="circle_red"></Box>
          </Box>

          <Box>
            <Typography
              // variant="h1"
              sx={{
                fontFamily: "VAG Rounded",
                fontWeight: 400,
                fontSize: "3.5rem",
                color: "#fff",
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
            >
              Privacy Statement
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              Your privacy is very important to MEGSAPP RSA (PTY) LTD
              (“megsApp”). megsApp has developed this to help you understand how
              we collect, communicate and potentially use any personal
              information that you have submitted to us. The definition of
              ‘personal identifiable information' is defined and detailed in the
              Protection of Personal Information Act 4 of 2013 (“POPI”).
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
            >
              Personal information we collect
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              We collect information directly from you when you purchase a
              product or services from us or when you submit enquiries to us or
              contact us, we'll ask for personal information, such as your name
              and surname, identity or passport number, megsApp mobile number,
              postal or street address, title, contact numbers or e-mail
              address.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                fontFamily: "VAG Rounded",
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              We may collect information from the use of our services, for
              example non-personal browsing habits and click patterns, e-mail
              address, IP address, telephone data information or username and
              password. Where possible, we will inform you what information is
              voluntary or mandatory for you to provide to us and the
              consequences for failing to provide the requested information.
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
            >
              How we use your collected information
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              megsApp may have to share your personal identifiable information
              with other companies or entities (third parties) with which we
              have a business relationship in order to provide the service you
              have requested. These third parties do not have the right to use
              the personal information we share with them beyond what is
              necessary to deliver your requested service. megsApp does not
              collect personally identifiable information about individuals
              except when such individuals specifically provide such. For
              example, such personally identifiable information may be gathered
              from the registration process. Information will be gathered on a
              voluntary basis.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                fontFamily: "VAG Rounded",
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              megsApp further reserves the right to perform statistical analyses
              of user behaviour and characteristics in order to measure interest
              in and use of the various areas of the Site and the Services and
              to inform advertisers of such information as well as the number of
              users that have been exposed to their advertising banners if
              applicable. We may disclose your information to other companies in
              the megsApp Group of companies, including their respective
              partners, agents and sub-contractors, for any of the above
              purposes. In addition, we may disclose your information where we
              are legally obliged to do so in terms of the laws of the Republic
              of South Africa.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "VAG Rounded",
                color: "#fff",
              }}
            >
              Disclosure Of Information
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              megsApp may disclose your personal information to third parties on
              the following basis:
            </Typography>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "1rem",
                color: "#fff",
                textAlign: "justify",
              }}
            >
              <li>Where we have your consent; or</li>
              <li>Where we are required to do so by law; or</li>
              <li>
                To our service providers who are involved in the delivery of
                products or services to you, where we have agreements in place
                to ensure that they comply with these privacy terms; or
              </li>
              <li>
                Where we are legally obliged to do so in terms of the laws of
                the Republic of South Africa.
              </li>
            </ul>

            <Typography
              variant="h5"
              sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
            >
              Information Security
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              megsApp shall take all reasonable steps to secure the content of
              the megsApp application and the information provided by and
              collected from users from a wide variety of security breaches such
              as unauthorized access and/or disclosure, etc. However, megsApp
              does not make any warranties or representations that content shall
              be 100% safe or secure, but megsApp will have applied all
              necessary due care and diligence towards preserving the security
              of information.
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
            >
              Access To Your Personal Information
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              You may change and/or any of your personal information that is
              held by megsApp by making the changes in the Application itself.
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontFamily: "VAG Rounded", color: "#fff", my: 3 }}
            >
              Changes To the Statement
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#fff",
                textAlign: "justify",
                my: 3,
              }}
            >
              megsApp reserves the right to amend this privacy statement at any
              time, without prior notice, by posting the amended statement on
              our website.
            </Typography>
          </Box>
          <Box
            ref={inputRef}
            sx={{
              position: "absolute",
              bottom: 0,
              right: "-75%",
              zIndex: -1,
              width: "100%",
            }}
          >
            <img
              src={"./assets/megsapp-clear-logo.webp"}
              style={{ width: "40%" }}
              alt="megsapp-logo"
            />
          </Box>
        </Grid>
      </Container>
    </>
  );
};
export default Privacy;
