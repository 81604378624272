import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const Terms = () => {
  const inputRef = useRef();
  const [div_distance, setDivDistance] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container className={loading ? "hide_map" : "show_map"} maxWidth={"xl"}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            direction={"column"}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: { xs: "flex-start", md: "flex-start" },
              textAlign: { xs: "left", md: "left" },
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "flex-end",
                position: "absolute",
                height: "35px",
                backgroundColor: "#fff",
                width: "100%",
                left: "-103%",
                top: "10px",
                borderRadius: 15,
                p: 1,
              }}
            >
              <Box className="circle_red"></Box>
            </Box>
            <Box>
              <Typography
                variant="h1"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Terms and conditions
              </Typography>
            </Box>

            {/* introduction */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Introduction
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                These Terms and Conditions are applicable to you when you make
                use of megsApp's Services which are activated when your prepaid
                SIM Card is connected to the megsApp Network through the
                Apparatus.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                By purchasing any of megsApp's Services you automatically bind
                yourselves to these Terms and Conditions and acknowledge that
                these Terms and Conditions:
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff" }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  may limit the risk or liability of megsApp or a third party;
                  and/or
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  may create risk or liability for you; and/or
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  may compel you to indemnify megsApp or a third party; and/or
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  serve as an acknowledgement, by you, of a fact.
                </ListItem>
              </List>
            </Box>
            {/* introduction */}

            {/* Definitions */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Definitions
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "Apparatus" means any mobile, transportable or portable cellular
                mobile terminal, handset, laptop, computer, modem or other
                apparatus which is approved by the Regulatory Authority and
                which is capable of connection by radio interface to the
                Network.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "megsApp" means megsApp RSA (Pty) Ltd (Registration Number
                2017/252078/07), a private company with limited liability duly
                registered and incorporated in accordance with the company laws
                of the Republic of South Africa;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "megsApp Group" means megsApp and any of its subsidiary
                companies;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "megsApp Representative" means a person who is duly authorised
                to represent megsApp with the role and function of selling and
                activating megsApp SIM cards;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "megsApp Services" means the cellular telecommunications service
                provided by means of the Network as well as such other
                additional related services, equipment, Value Added Services
                and/or Financial Services;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "Network" means the public mobile telecommunications system
                operated and made available by megsApp or any other members of
                the megsApp Group;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "Regulatory Authority" means the Independent Communication
                Authority of South Africa (ICASA) or any similar authority that
                is established in the future to perform or take over the
                functions of ICASA;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "SIM Card" means the Subscriber Identity Module Card allocated
                to you to enable you to gain access to the Network by using the
                SIM Card in the Apparatus;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "Starter Pack" means the pack that includes the user guide and
                SIM Card;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "Subscriber Identity Module Card" means the SIM Card allocated
                to you which identifies your subscription to megsApp;
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "Terms and Conditions" means these terms and conditions and any
                other terms and conditions which may be amended in the future
                and uploaded on the megsApp website
                (https://megsapp.com/termsandconditions); and
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                "Value-Added Services" means and services provided by megsApp
                which are non-core services and are services beyond standard
                voice calls, the content of which may not have anything to do
                with megsApp's mobile network and/or services.
              </Typography>
            </Box>
            {/* Definitions */}

            {/* Provision */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Provision of the megsApp Services
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                In order to receive your new cellphone number and to activate
                your SIM Card found in your Starter Pack, you must register your
                SIM Card, in terms of the provisions of the Regulation of
                Interception of Communications and Provision of Communications
                Related Information Act, 2002 (“RICA”). The megsApp
                Representative selling the Starter Pack will assist you in
                ensuring the SIM Card has been correctly RICA'd and register you
                on the Network immediately subsequent to your purchase of the
                Starter Pack.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Once your SIM Card has been RICA'd, insert your SIM Card into
                your Apparatus and you will be connected to the megsApp Network
                and megsApp Services and receive your cellphone number.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Subject to the Terms and Conditions, megsApp will activate the
                SIM Card and maintain the availability of the megsApp Services
                to you whilst your SIM Card is active on the Network.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                megsApp provides free access to the megsApp Services and
                platforms on the megsApp mobile application “(megsApp App”)
                which is free of charge for all registered megsApp users.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                megsApp operates using a fair usage policy which grants each
                megsApp user 100MB (one hundred megabytes) of free data access
                per day to be used on the megsApp App. Should the megsApp user
                use more than the daily allocated 100MB (one hundred megabytes)
                on the megsApp App, megsApp reserves the right to charge the
                megsApp user for the additional data used.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                megsApp reserves the right to adjust, amend or discontinue the
                megsApp Services, by giving advance notice where reasonably
                possible and dependent on the circumstances, based on the
                megsApp user's use of the megsApp Services.
              </Typography>
            </Box>
            {/* Provision */}

            {/* Portability */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Cellphone Number Portability
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Should you wish to port-in your cellphone number from your
                previous network provider you will be required to indicate this
                when the megsApp Representative is registering you on the
                Network. Should you qualify to port your cellphone number, an
                additional administration fee will be charged.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You may not port if:
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff", pb: 2 }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  any network operator has suspended your service;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  your cellphone number has already been disconnected;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  you have ported your cellphone number in the previous 60
                  (sixty) days;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  your cellphone number is already waiting to be ported;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  you have provided the incorrect details; or
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  you do not have permission to port for whatever reason.
                </ListItem>
              </List>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Porting is not capable of taking place on Sundays or public
                holidays and can only be activated in the late evening on the
                port date you request, or the following day.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                A port may be reversed on the instruction of the megsApp user
                but will only be permitted in the following circumstances:
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff", pb: 2 }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  within 60 (sixty) days after the date that the number was
                  ported from the megsApp user's previous service provider; and
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  the megsApp user and their previous service provider agree to
                  the reversal of the port.
                </ListItem>
              </List>
            </Box>
            {/* Portability */}

            {/* Changing */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Changing of Cellphone Number
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                It is possible for the megsApp user to change their megsApp
                cellphone number without the need to purchase a new SIM card.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                The process to be followed in order to change your megsApp
                cellphone number is available on the megsApp App.
              </Typography>
            </Box>
            {/* Changing */}

            {/* Value-Added */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Value-Added Services
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You acknowledge that should you subscribe to any of the Value
                Added Services, the provision of such Value Added Services will
                be subject to the terms and conditions applicable thereto which
                you undertake to make yourself familiar with.
              </Typography>
            </Box>
            {/* Value-Added */}

            {/* Disclosure */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Disclosure of Information
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You consent that megsApp may, to the extent permitted by law,
                receive or disclose your personal information, documents,
                detailed call records, credit profile information and/or any
                other credit information from or to:
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff", pb: 2 }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  any credit providers, credit bureau or credit reporting
                  agencies;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  any law enforcement agencies that require the information for
                  the prevention or investigation of criminal activities;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  any of megsApp's shareholders, related entities, suppliers,
                  agents, professional advisors or any company with the megsApp
                  Group for marketing purposes, subject to your right to
                  restrict receipt of unwanted marketing material or other
                  rights in terms of the Consumer Protection Act, 2008 and the
                  Protection of Personal Information Act 4 of 2013;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  any of megsApp's shareholders, related entities, suppliers,
                  agents or professional advisors for reporting, accounting,
                  product supply and service and/or auditing purposes; or
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  any company within the megsApp Group for any purpose connected
                  with the megsApp Services or the Network or for incentive
                  scheme purposes.
                </ListItem>
              </List>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Despite any part of the Terms and Conditions that may indicate
                otherwise, megsApp shall be entitled to utilise your detailed
                call records for any lawful purpose including but not limited to
                tracing and/or collection purposes.
              </Typography>
            </Box>
            {/* Disclosure */}

            {/* Acknowledgement */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Acknowledgement
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You acknowledge and agree that–
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff", pb: 2 }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  the service quality and coverage available to you shall be
                  limited to that provided by the Network in accordance with the
                  requirements of the Electronic and Communications Act, 2005
                  (as amended) (“ECTA”) and the Regulatory Authority
                  requirements. The megsApp Services may, from time to time, be
                  adversely affected by such physical features such a buildings
                  and underpass, as well as atmospheric conditions and other
                  causes of interface; and
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  to the extent permitted by law, megsApp shall not be liable
                  for non-availability of the megsApp Services.
                </ListItem>
              </List>
            </Box>
            {/* Acknowledgement */}

            {/* Liability */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Liability
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You indemnify and hold megsApp and each company within the
                megsApp Group harmless against any expense, loss, claim, harm or
                damage brought against, suffered or sustained by you or any
                third party, which arises directly or indirectly out of a breach
                of these Terms and Conditions by you or by your use of the
                megsApp Services and/or the Apparatus, other than in respect of
                losses caused by the gross negligence or intentional misconduct
                of megsApp or any company within the megsApp Group or any of
                their respective employees, directors or agents.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Without affecting the generality of clause 8.1 above, megsApp
                shall not be liable to you for any breach of these Terms and
                Conditions or failure on megsApp's part to perform any
                obligations as a result of technical problems relating to the
                Network, termination of any license to operate or use the
                Network, act of God, government control, restrictions or
                prohibitions or other government act or omission, whether local
                or national, act of default of any supplier, agent or
                sub-contractor, industrial disputes or any other cause beyond
                the control of megsApp.
              </Typography>
            </Box>
            {/* Liability */}

            {/* Undertakings */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Undertakings
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You shall–
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff", pb: 2 }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  comply with all instructions issued by megsApp which concern
                  your use of the megsApp Services, the Apparatus or related
                  matters;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  not to be entitled to commercially exploit the megsApp
                  Services in any manner whatsoever without megsApp's prior
                  written consent, including without limitation, the provision
                  of telecommunication services to third parties;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  provide megsApp with all such necessary information as megsApp
                  may, in its sole discretion, reasonable and lawfully require;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  only make use of an Apparatus which is approved for use in
                  conjunction with the Network by megsApp; and
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  engage with megsApp, other members of the megsApp Group and
                  their respective staff and agents in a courteous, cordial and
                  respectful manner at all times.
                </ListItem>
              </List>
            </Box>
            {/* Undertakings */}

            {/* Suspension/Disconnection */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Suspension/Disconnection
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                megsApp may from time to time, by giving you advance notice
                where reasonably possible and dependent on the circumstances,
                suspend the megsApp Services or disconnect your cellphone number
                from the Network for a period to be determined by megsApp, in
                any one of the following circumstances:
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff", pb: 2 }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  if the megsApp Services have not been used by you for a period
                  in excess of 60 (sixty) days;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  during any technical failure, modification or maintenance of
                  the Network;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  if you fail to comply with any of the Terms and Conditions;
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  if so directed by the Regulatory Authority; or
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  in any other instance specifically provided for in the Terms
                  and Conditions.
                </ListItem>
              </List>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Having regard to the circumstances at the time of the
                suspension, disconnection or reconnection, as the case may be,
                megsApp shall be entitled to levy a reasonable fee in respect of
                such suspension, disconnection and/or any reconnection.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                At the time of disconnection should the megsApp user have any
                data or minutes in credit, the same will not be able to be paid
                in any way to the megsApp user.
              </Typography>
            </Box>
            {/* Suspension/Disconnection */}

            {/* Communication */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Communication
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You hereby consent to the dispatch or transmission by megsApp of
                all and necessary communication in terms of ECTA or subsequently
                enacted and relevant legislation. You are entitled to inform
                megsApp if you do not wish to receive any communications for
                purposes of direct marketing and in this instance, megsApp will
                refrain from circulating any further such material to you.
              </Typography>
            </Box>
            {/* Communication */}

            {/* Disputes */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Disputes
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Any dispute relating to the Network may be referred to the ICASA
                or to the National Consumer Commission established under the
                Consumer Protection Act, 2008.
              </Typography>
            </Box>
            {/* Disputes */}

            {/* Lost or Stolen */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Lost or Stolen SIM Cards and/or Apparatus
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You are responsible for the safekeeping of your SIM Card and/or
                Apparatus.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You must notify megsApp immediately if you realize that your SIM
                Card and/or Apparatus has been lost or stolen.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                You must also, within 24 (twenty-four) hours of you realizing
                that your SIM Card and/or Apparatus has been lost or stolen,
                report this to the South African Police Service.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                In the event that your SIM card is lost or stolen, it is
                possible to replace the SIM card and keep the existing number by
                exercising one of the following options:
              </Typography>

              <List sx={{ listStyle: "point", color: "#fff", pb: 2 }}>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  purchase a Starter Pack from a megsApp Representative and
                  inform the megsApp Representative that you wish to retain your
                  existing number before the megsApp Representative commences
                  the RICA process; or
                </ListItem>
                <ListItem
                  sx={{
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  purchase a replacement SIM card in the megsApp App which will
                  provide the relevant details and procedures to be followed.
                </ListItem>
              </List>
            </Box>
            {/* Lost or Stolen */}

            {/* OTP */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Password and/or One Time Pin
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                If you have a password or One Time PIN (“OTP”) sent to your SIM
                Card or stored on your Apparatus, you undertake to keep it
                secure and undertake that no other person shall use the megsApp
                Services by means of utilising your password or OTP, and you
                further acknowledge that you are responsible for ensuring that
                no unauthorised access to the password or OTP is obtained and
                that you will be liable for all such activities conducted
                pursuant to such use, whether authorised or not.
              </Typography>
            </Box>
            {/* OTP */}

            {/* General */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                General
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of South Africa, and you shall submit
                to the jurisdiction of the South African courts.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                These Terms and Conditions are severable, in that if any
                provision is determined to be illegal or unenforceable by any
                court holding the necessary jurisdiction, then such provision
                shall be deemed to have been deleted without affecting the
                remaining provision of the Terms and Conditions.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                megsApp's failure to exercise any particular rights or provision
                of these Terms and Conditions shall not constitute a waiver of
                such right or provision unless acknowledged and agreed to by
                megsApp in writing.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                  pb: 2,
                }}
              >
                Any amended terms and conditions published in the media, or on
                the megsApp website (https://megsapp.com/termsandconditions)
                will form part of the terms and conditions of the megsApp
                Services, to which terms and conditions you agree to be bound.
              </Typography>
            </Box>
            {/* General */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Terms;
