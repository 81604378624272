import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";

const SearchBox = ({ mapInstance, mapApi, onPlacesChanged, placeholder }) => {
  const input = useRef(null);
  const searchBox = useRef(null);
  const maps = mapApi;

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) {
      console.log(searchBox.current.getPlaces());
      onPlacesChanged(searchBox.current.getPlaces());
    }
  }, [onPlacesChanged, searchBox]);

  useEffect(() => {
    if (!searchBox.current && maps) {
      searchBox.current = new maps.places.SearchBox(input.current);
      searchBox.current.addListener("places_changed", handleOnPlacesChanged);
    }

    return () => {
      if (maps) {
        searchBox.current = null;
        maps.event.clearInstanceListeners(searchBox);
      }
    };
  }, [maps, handleOnPlacesChanged]);

  return (
    <Box sx={{ width: "100%", position: "absolute", top: -60 }}>
      <Grid item xs={12} direction={"column"}>
        <Card
          elevation={1}
          sx={{
            p: { xs: 1, sm: 4 },
            backgroundColor: "#fff",
            borderRadius: "15px",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            position: "relative",
            overflow: "visible",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              p: 2,
              pl: { xs: 1, sm: 5 },
              pr: { xs: 1, sm: 5 },
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontFamily: "VAG Rounded", color: "#002846" }}
            >
              Find a{" "}
              <Typography
                variant="h3"
                sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }}
                component="span"
              >
                {" "}
                megsApp
              </Typography>{" "}
              SIM Trader
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: "#4c4f54",
              }}
            >
              Our expert megstraders will onboard you within a few minutes!
            </Typography>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              p: 2,
              pl: 5,
              pr: 5,
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              width: { xs: "80%", md: "inherit" },
            }}
          >
            <input
              className={"locations_searchbar"}
              ref={input}
              placeholder={placeholder}
              type="text"
            />
          </Box>
        </Card>
      </Grid>
    </Box>
  );
};

export { SearchBox };
