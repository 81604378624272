import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const API_SALT = process.env.REACT_APP_API_SALT;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

axios.defaults.headers.common["Authorization"] = "Bearer " + API_TOKEN;

export const getTraderLocations = async (userLocation) => {
  console.log("getTraderLocations", userLocation);
  try {
    let url = `${API_URL}/api/v1/customer/places`;
    let { data } = await axios.post(url, {
      lat: userLocation.lat,
      long: userLocation.lng,
    });

    let status_code = data.status_code;
    console.log("getTraderLocations", status_code);

    if (status_code === 1001) {
      let locations = data.data;
      console.log("getTraderLocations", locations);
      return locations;
    }
  } catch (ex) {
    console.error();
  }
};
