import ShopifyBuy from "@shopify/buy-button-js";
import { useEffect } from "react";

const shopifyClient = ShopifyBuy.buildClient({
  domain: "megsapp.myshopify.com",
  storefrontAccessToken: "bee68d2e376716f7aa2b01a5ac2a937d", // previously 'apiKey', now deprecated
});

const ui = ShopifyBuy.UI.init(shopifyClient);

export default function ShopifyButton({ id }) {
  useEffect(() => {
    ui.createComponent("product", {
      id,
      node: document.getElementById(`buy-now-${id}`),
      moneyFormat: "R%20%7B%7Bamount%7D%7D",
      options: {
        product: {
          styles: {
            product: {
              "@media (min-width: 601px)": {
                "max-width": "calc(25% - 20px)",
                "margin-left": "20px",
                "margin-bottom": "50px",
              },
            },
            title: {
              color: "#ffffff",
            },
            button: {
              "font-family": "Open Sans, sans-serif",
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
              ":hover": {
                "background-color": "#cd1c22",
              },
              "background-color": "#e41f26",
              ":focus": {
                "background-color": "#cd1c22",
              },
              "border-radius": "15px",
              "padding-left": "61px",
              "padding-right": "61px",
            },
            quantityInput: {
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
            },
            price: {
              color: "#ffffff",
            },
            compareAt: {
              color: "#ffffff",
            },
            unitPrice: {
              color: "#ffffff",
            },
          },
          contents: {
            img: false,
            button: false,
            buttonWithQuantity: true,
            title: false,
            price: false,
          },
          text: {
            button: "Add to cart",
          },
          googleFonts: ["Open Sans"],
        },
        productSet: {
          styles: {
            products: {
              "@media (min-width: 601px)": {
                "margin-left": "-20px",
              },
            },
          },
        },
        modalProduct: {
          contents: {
            img: false,
            imgWithCarousel: true,
            button: false,
            buttonWithQuantity: true,
          },
          styles: {
            product: {
              "@media (min-width: 601px)": {
                "max-width": "100%",
                "margin-left": "0px",
                "margin-bottom": "0px",
              },
            },
            button: {
              "font-family": "Open Sans, sans-serif",
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
              ":hover": {
                "background-color": "#cd1c22",
              },
              "background-color": "#e41f26",
              ":focus": {
                "background-color": "#cd1c22",
              },
              "border-radius": "15px",
              "padding-left": "61px",
              "padding-right": "61px",
            },
            quantityInput: {
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
            },
            title: {
              "font-family": "Open Sans, sans-serif",
              "font-weight": "bold",
              "font-size": "26px",
              "color": "#4c4c4c",
            },
            price: {
              "font-family": "Open Sans, sans-serif",
              "font-weight": "normal",
              "font-size": "18px",
              "color": "#4c4c4c",
            },
            compareAt: {
              "font-family": "Open Sans, sans-serif",
              "font-weight": "normal",
              "font-size": "15.299999999999999px",
              "color": "#4c4c4c",
            },
            unitPrice: {
              "font-family": "Open Sans, sans-serif",
              "font-weight": "normal",
              "font-size": "15.299999999999999px",
              "color": "#4c4c4c",
            },
          },
          googleFonts: ["Open Sans"],
          text: {
            button: "Add to cart",
          },
        },
        option: {},
        cart: {
          styles: {
            button: {
              "font-family": "Open Sans, sans-serif",
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
              ":hover": {
                "background-color": "#cd1c22",
              },
              "background-color": "#e41f26",
              ":focus": {
                "background-color": "#cd1c22",
              },
              "border-radius": "15px",
            },
            title: {
              color: "#002846",
            },
            header: {
              color: "#002846",
            },
            lineItems: {
              color: "#002846",
            },
            subtotalText: {
              color: "#002846",
            },
            subtotal: {
              color: "#002846",
            },
            notice: {
              color: "#002846",
            },
            currency: {
              color: "#002846",
            },
            close: {
              "color": "#002846",
              ":hover": {
                color: "#002846",
              },
            },
            empty: {
              color: "#002846",
            },
            noteDescription: {
              color: "#002846",
            },
            discountText: {
              color: "#002846",
            },
            discountIcon: {
              fill: "#002846",
            },
            discountAmount: {
              color: "#002846",
            },
          },
          text: {
            total: "Subtotal",
            button: "Checkout",
          },
          popup: false,
          googleFonts: ["Open Sans"],
        },
        toggle: {
          styles: {
            toggle: {
              "font-family": "Open Sans, sans-serif",
              "background-color": "#e41f26",
              ":hover": {
                "background-color": "#cd1c22",
              },
              ":focus": {
                "background-color": "#cd1c22",
              },
            },
            count: {
              "font-size": "16px",
            },
          },
          googleFonts: ["Open Sans"],
        },
        lineItem: {
          styles: {
            variantTitle: {
              color: "#002846",
            },
            title: {
              color: "#002846",
            },
            price: {
              color: "#002846",
            },
            fullPrice: {
              color: "#002846",
            },
            discount: {
              color: "#002846",
            },
            discountIcon: {
              fill: "#002846",
            },
            quantity: {
              color: "#002846",
            },
            quantityIncrement: {
              "color": "#002846",
              "border-color": "#002846",
            },
            quantityDecrement: {
              "color": "#002846",
              "border-color": "#002846",
            },
            quantityInput: {
              "color": "#002846",
              "border-color": "#002846",
            },
          },
        },
      },
    });
  }, []);

  return <div id={`buy-now-${id}`} />;
}
