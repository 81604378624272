import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import DoneIcon from "@mui/icons-material/Done";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const About = () => {
  const inputRef = useRef();
  const [div_distance, setDivDistance] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    const current = inputRef.current.getBoundingClientRect();
    setDivDistance(current.right);
  }, [inputRef]);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container className={loading ? "hide_map" : "show_map"} maxWidth={"xl"}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            direction={"column"}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: { xs: "flex-start", md: "flex-start" },
              textAlign: { xs: "left", md: "left" },
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "flex-end",
                position: "absolute",
                height: "35px",
                backgroundColor: "#fff",
                width: "100%",
                left: "-103%",
                top: "10px",
                borderRadius: 15,
                p: 1,
              }}
            >
              <Box className="circle_red"></Box>
            </Box>
            <Box>
              <Typography
                variant="h1"
                sx={{ fontFamily: "VAG Rounded", color: "#fff" }}
              >
                About Us
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#fff" }}
              >
                megsApp is SA's newest Mobile Network focused on simplicity,
                honesty, and affordability.
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 2, fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Why join megsApp{" "}
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }}
                  component="span"
                >
                  {" "}
                  today?
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> All our bundles
                are simple and super affordable
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> None of our
                bundles, airtime, or rewards ever expire
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> You can earn FREE
                data every single day with us
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> You'll get the
                best rates on voice and SMS
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> No out-of-bundle
                rate charges ever
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> You can change or
                keep your number
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> Best nationwide
                connectivity - we run on the MTN Network
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> Everything is
                straightforward, no BS!
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{ mt: 2, fontFamily: "VAG Rounded", color: "#fff" }}
              >
                Get your SIM & download our App{" "}
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }}
                  component="span"
                >
                  {" "}
                  today?
                </Typography>
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }}
              >
                #achievemore
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                mt: 4,
              }}
            >
              <Button
                color="secondary"
                to="/get-sim"
                component={RouterLink}
                size="large"
                variant="contained"
                sx={{
                  pl: 5,
                  pr: 5,
                  textTransform: "initial",
                  borderRadius: "10px",
                }}
                endIcon={<ArrowRightIcon />}
              >
                Get a SIM
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            direction={"column"}
            sx={{
              display: { xs: "flex", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box ref={inputRef}>
              <img
                src={"./assets/megsapp-clear-logo.webp"}
                style={{ width: "140%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default About;
