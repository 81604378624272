/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Route, Switch } from "react-router-dom";

import About from "../views/About";
import Documentation from "../views/Documentation";
import FindTrader from "../views/FindTrader";
import GetSim from "../views/GetSim";
import Home from "../views/Home";
import Privacy from "../views/Privacy";
import SelfPorting from "../views/SelfPorting";
import SelfRica from "../views/SelfRica";
import Terms from "../views/Terms";
import TermsAndConditions from "../views/TermsAndConditions";
import CompetitionTerms from "../views/CompetitionTerms";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/get-sim">
        <GetSim />
      </Route>
      <Route exact path="/self-rica">
        <SelfRica />
      </Route>
      <Route exact path="/port-number">
        <SelfPorting />
      </Route>
      <Route exact path="/trader-near-me">
        <FindTrader />
      </Route>
      <Route exact path="/termsandconditions">
        <Terms />
      </Route>
      <Route exact path="/terms">
        <TermsAndConditions />
      </Route>
      <Route exact path="/privacy-policy">
        <Privacy />
      </Route>
      <Route exact path="/competition-terms">
        <CompetitionTerms />
      </Route>
      <Route exact path="/documentation">
        <Documentation />
      </Route>
    </Switch>
  );
};

export { Routes };
