import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuIcon from "@mui/icons-material/Menu";

const pages = [
  "Home",
  "About Us",
  "Self RICA",
  "Support",
  "About",
  "Get a SIM",
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      color="transparent"
      enableColorOnDark
    >
      <Container sx={{ pt: 2, pb: { xs: 2, md: 0 } }} maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 0,
              p: 2,
              display: { xs: "flex", md: "flex" },
              textAlign: { xs: "left", md: "center" },
            }}
          >
            <a href={"/"}>
              <img
                className="app_logo"
                src={"./assets/megsapp-logo-white.png"}
              />
            </a>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/"
                  style={{
                    my: 2,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Home
                </a>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/about"
                  style={{
                    my: 2,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  About Us
                </a>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/self-rica"
                  style={{
                    my: 2,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Self RICA
                </a>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/port-number"
                  style={{
                    my: 2,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Port my number
                </a>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="https://support.megsapp.com/"
                  target={"_blank"}
                  style={{
                    my: 2,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  rel="noreferrer"
                >
                  Support
                </a>
              </MenuItem>
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/privacy-policy"
                  style={{
                    my: 2,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/terms"
                  style={{
                    my: 2,
                    mr: 1,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Terms and Conditions
                </a>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/competition-terms"
                  style={{
                    my: 2,
                    mr: 1,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Competition Terms
                </a>
              </MenuItem> */}


              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="/get-sim"
                  style={{
                    my: 2,
                    color: "#002846",
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                >
                  Get a SIM
                </a>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              to="/"
              component={RouterLink}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              Home
            </Button>
            <Button
              to="/about"
              component={RouterLink}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              About Us
            </Button>
            <Button
              to="/self-rica"
              component={RouterLink}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              Self RICA
            </Button>
            <Button
              to="/port-number"
              component={RouterLink}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              Port my number
            </Button>

            <Button
              href="https://support.megsapp.com/"
              target={"_blank"}
              sx={{
                my: 2,
                mr: 1,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              Support
            </Button>
            {/* <Button
              to="/privacy-policy"
              component={RouterLink}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              Privacy Policy
            </Button>
            <Button
              to="/terms"
              component={RouterLink}
              sx={{
                my: 2,
                mr: 1,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              Terms and Conditions
            </Button>

            <Button
              to="/competition-terms"
              component={RouterLink}
              sx={{
                my: 2,
                mr: 1,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              Competition Terms
            </Button> */}

            <Button
              to="/get-sim"
              component={RouterLink}
              color={"info"}
              variant="contained"
              sx={{ textTransform: "initial", borderRadius: "10px" }}
              endIcon={<ArrowRightIcon />}
            >
              Get a SIM
            </Button>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="input"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
